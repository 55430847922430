import axios from 'axios';

const apiUrl = process.env.REACT_APP_URL_API || 'http://localhost:3500';

let instance = axios.create({
    baseURL: apiUrl + '/exam'
});

export const getQuestions = async (body) => {
    const url = '/get-question';
    return instance.post(url, body)
        .then(response => response.data)
        .catch(error => Promise.reject(error.response.data));
}