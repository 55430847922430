import React, { useEffect, useState, useRef } from 'react'
import classNames from 'classnames'

import { useAuth } from '../../contexts/AuthContext';
import { createAttempt, createResult } from '../../model/results';
import { Button, DecorativeSquare, ExamForm } from '../../components'
import { holland } from '../../util/exams/holland'
import { LeftArrow } from '../../assets/icons'

import css from './ExamPage.module.css'

const EXAM_ID = 'e68f8c56-ae43-4908-9542-1ac490de854b'

const ExamPage = () => {

    const { user } = useAuth();

    const [startExam, setStartExam] = useState(false)
    const [finishedExam, setFinishedExam] = useState(false)
    const [currentSection, setCurrentSection] = useState(0)
    const [attempt, setAttempt] = useState(null)

    useEffect(() => {
        const generateAttempt = async () => {
            const body = {
                user_id: user?.id_user,
                examId: EXAM_ID
            }

            const response = await createAttempt(body)

            setAttempt(response.data)
        }

        if(startExam === true) {
            if(attempt === null) {
                generateAttempt()
            }
        }
    }, [startExam, attempt])

    const handleSubmit = async (values) => {

        console.log("values", values)
        const body = {
            id_attempt: attempt.id_attempt,
            id_user: user?.id_user,
            ...values
        }

        console.log("body", body)

        const response = await createResult(body)
        console.log("Result", response)

        if(currentSection < holland.exam.length - 1) {
            setCurrentSection(currentSection + 1)

            // Scroll to top
            document.querySelector('header').scrollIntoView({ behavior: 'smooth'})

            // Reset form
            document.getElementById('holland-exam').reset()
        } else {
            setFinishedExam(true)
            setStartExam(false)
        }
    }

    return (
        <div className={css.container}>
            <header className={classNames(css.header, {
                [css.compress]: startExam === true && finishedExam === false
            })}>
                <DecorativeSquare color="#26d07c" positionTop="16%" positionRight="100px" hide={startExam}/>
                <DecorativeSquare color="#007864" positionTop="50%" positionRight="0px" hide={startExam}/>
                <DecorativeSquare color="#00a499" positionTop="50%" positionLeft="0" hide={startExam}/>
                <a className={css.breadcrumb} href="/">
                    <LeftArrow /><span>Atrás</span>
                </a>
                <h1>{holland.title}</h1>
                <p>
                    {holland.description}
                </p>
            </header>
            {startExam === false && finishedExam === false && (
                <section className={css.exam}>
                    <h2>INSTRUCCIONES</h2>
                    <p>
                        {holland.instructions}
                    </p>
                    <Button
                        className={css.button}
                        text="Comenzar"
                        onClick={() => {
                            setStartExam(true)

                            // Scroll to top
                            document.querySelector('header').scrollIntoView({ behavior: 'smooth'})
                        }}
                    />
                </section>
            )}
            
            {startExam === true && finishedExam === false && (
                <div className={css.exam}>
                    <ExamForm
                        formId="holland-exam"
                        handleSubmit={(values) => {   
                            console.log("values in form", values)      
                            
                            for (const element in values) {
                                handleSubmit(values[element])
                            }
                        }}
                        section={holland.exam[currentSection]}
                        currentSection={currentSection}
                        setCurrentSection={setCurrentSection}
                        totalSections={holland.exam.length}
                    />
                </div>
            )}

            {finishedExam === true && startExam === false && (
                <section className={css.exam}>
                    <h2>¡Felicidades!</h2>
                    <p>Has terminado el examen de Perfilamiento, tu asesor se pondrá en contacto contigo.</p> 
                </section>
            )}
        </div>
    )
}

export default ExamPage