import PropTypes from "prop-types";

const LeftArrow = props => {
    const {
        className,
        type,
        ...rest
    } = props;

    if (type === 'right') {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className={className} {...rest}>
                <path d="M8.6458 5.68693C8.45053 5.49167 8.45053 5.17508 8.6458 4.97982C8.84106 4.78456 9.15764 4.78456 9.3529 4.97982L12.0196 7.64649C12.1172 7.74412 12.166 7.87208 12.166 8.00004C12.166 8.06784 12.1525 8.13248 12.1281 8.19143C12.1037 8.25041 12.0675 8.30566 12.0196 8.35359L9.3529 11.0203C9.15764 11.2155 8.84106 11.2155 8.6458 11.0203C8.45053 10.825 8.45053 10.5084 8.6458 10.3132L10.4589 8.50004H3.99933C3.72322 8.50004 3.49933 8.27618 3.49933 8.00004C3.49933 7.7239 3.72322 7.50004 3.99933 7.50004H10.4589L8.6458 5.68693Z" fill="white" className={className}/>
            </svg>
        )
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className={className} {...rest}>
            <path d="M7.3542 5.68693C7.54947 5.49167 7.54947 5.17508 7.3542 4.97982C7.15894 4.78456 6.84236 4.78456 6.6471 4.97982L3.98043 7.64649C3.8828 7.74412 3.83398 7.87208 3.83398 8.00004C3.83398 8.06784 3.84748 8.13248 3.87192 8.19143C3.89632 8.25041 3.93249 8.30566 3.98043 8.35359L6.6471 11.0203C6.84236 11.2155 7.15894 11.2155 7.3542 11.0203C7.54947 10.825 7.54947 10.5084 7.3542 10.3132L5.54109 8.50004H12.0007C12.2768 8.50004 12.5007 8.27618 12.5007 8.00004C12.5007 7.7239 12.2768 7.50004 12.0007 7.50004H5.54109L7.3542 5.68693Z" fill="white" className={className}/>
        </svg>
    )
}

LeftArrow.propTypes = {
    className: PropTypes.string
}

LeftArrow.defaultProps = {
    className: ''
}

export default LeftArrow;