import React from 'react'
import PropTypes from 'prop-types'

import { Button, CheckboxGroup, RadioRange } from '..'

import css from './ExamForm.module.css'

const ExamForm = (props) => {
    const {
        handleSubmit,
        section,
        currentSection,
        setCurrentSection,
        totalSections,
        formId
    } = props

    const onSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        const formData = new FormData(form)
        
        const values = []
        const selectedValues = []

        for (let [key, value] of formData.entries()) {
            
            const question = section.questions.find(question => question.id_question+"" === key+"")

            // If question is the same as the key, then push the value to the selectedValues array then save the values in the values array
            if (question.question_type === 'checkbox_group') {
                selectedValues.push({
                    answer_id: value,
                    answer_text: question.options.find(option => option.id_option === value).label
                })
            } else {
                values.push({
                    answer_id: value,
                    answer_text: question.options.find(option => option.id_option === value).label,
                    id_question: question.id_question,
                    question_text: question.label
                })
            }
        }

        if (selectedValues.length > 0) {
            handleSubmit([{
                answer_id: selectedValues.map(value => value.answer_id).join(','),
                answer_text: JSON.stringify(selectedValues),
                id_question: section.questions[0].id_question,
                question_text: section.questions[0].label
            }])
            return
        } else {
            handleSubmit(values)
        }
    }
    return (
        <form className={css.form} onSubmit={onSubmit} id={formId}>
            
            {section.questions.map((question, index) => {
                const type = question.question_type;

                if (type === 'checkbox_group') {
                    return (
                        <CheckboxGroup
                            key={index}
                            id={question.id_question}
                            name={question.id_question}
                            options={question.options}
                            label={question.label}
                        />
                    )
                }

                if (type === 'radio_range') {
                    return (
                        <RadioRange
                            key={index}
                            id={question.id_question}
                            name={question.id_question}
                            options={question.options}
                            label={question.label}
                        />
                    )
                }

                if (type === 'radio') {
                    return (
                        <div key={index} className={css.radio__group}>
                            <label className={css.label}>{question.label}</label>
                            {question.options.map((option, index) => (
                                <label key={index} className={css.option}>
                                    <input
                                        type="radio"
                                        id={option.id_option}
                                        name={question.id_question}
                                        value={option.id_option}
                                    />
                                    {option.label}
                                </label>
                            ))}
                        </div>
                    )
                }

            })}

            <div className={css.button__container}>
                {!totalSections ? (
                    <Button
                        className={css.button}
                        text="Siguiente"
                        type="submit"
                    />
                ) : currentSection <= totalSections - 1 && (
                    <Button
                        className={css.button}
                        text={currentSection === totalSections - 1 ? "Finalizar" : "Siguiente"}
                        type="submit"
                    />
                )}
            </div>
        </form>
    )
}

ExamForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    currentSection: PropTypes.number.isRequired,
}

export default ExamForm