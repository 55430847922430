export const interests = [
    {
        id: "animales",
        label: "Animales",
        path: "/images/animales.png"
    },
    {
        id: "anime",
        label: "Anime",
        path: "/images/anime.png"
    },
    {
        id: "arte",
        label: "Arte",
        path: "/images/arte.png"
    },
    {
        id: "ciencia",
        label: "Ciencia",
        path: "/images/ciencia.png"
    },
    {
        id: "ejercicio",
        label: "Ejercicio",
        path: "/images/ejercicio.png"
    }, 
    {
        id: "humor",
        label: "Humor",
        path: "/images/humor.png"
    },
    {
        id: "musica",
        label: "Música",
        path: "/images/musica.png"
    },
    {
        id: "naturaleza",
        label: "Naturaleza",
        path: "/images/naturaleza.png"
    },
    {
        id: "negocios",
        label: "Negocios",
        path: "/images/negocios.png"
    }, 
    {
        id: "viajes",
        label: "Viajes",
        path: "/images/viajes.png"
    },
    {
        id: "videojuegos",
        label: "Videojuegos",
        path: "/images/videojuegos.png"
    }
]