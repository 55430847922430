import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { DecorativeSquare, Input } from '../../components';
import { useForm } from '../../hooks/useForm';
import { useAuth } from '../../contexts/AuthContext';

import css from './HomePage.module.css';


const HomePage = () => {

    const { user, error } = useAuth();
    const navigate = useNavigate();

    if (error) {
        navigate('/login');
    }

    useEffect(() => {  
        if (!(user?.profile?.hobbies && user?.profile?.education_level && user?.name && user?.last_name)) {
            navigate('/profile');
        }
    }, [user, navigate]);

    const [formValues, , handleInputChange] = useForm({
        helpText: ""
    })

    const { helpText } = formValues

    return (
        <div className={css.container}>
            <header className={css.header}>
                <DecorativeSquare color="#26d07c" positionTop="150px" positionRight="100px" />
                <DecorativeSquare color="#007864" positionTop="250px" positionRight="0px" />
                <DecorativeSquare color="#00a499" positionTop="50%" positionLeft="0" />
                <div className={css.welcome__message}>
                    <h1 className={css.typewriter}>Hola {user?.name},</h1>
                    <p className={css.paragraphWriter}>
                        Estoy para ayudarte, aquí podrás conocer todo lo relacionado a tu vivencia en Tecmilenio.
                        {/* <strong>¿Cómo puedo ayudarte hoy?</strong> */}
                    </p>
                </div>
                {/* <Input
                    className={css.input}
                    type="text"
                    placeholder="Escribe aquí..."
                    value={helpText}
                    key='helpText'
                    name='helpText'
                    onChange={handleInputChange} /> */}
            </header>

            <section className={css.news__section}>
                <h2>NOTICIAS</h2>
                <div className={css.news__content}>
                    <div className={css.news__item}>
                        <h3>Lorem ipsum dolor sit amet:</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        </p>
                    </div>
                    <div className={css.news__item}>
                        <h3>Lorem ipsum dolor sit amet:</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default HomePage;
