import React, { useState } from 'react'
import css from './RelatedCareers.module.css'

export const RelatedCareers = ({ career }) => {
    const [colapesed, setColapesed] = useState(false)

    const colapesing = () => setColapesed(!colapesed)
    return (
        <div className={css.career__item}>
            <h3 className={css.career__title}>{career.career}</h3>
            {colapesed === false && <div className={css.career__details}>
                {
                    career.topics.map(topic => (
                        <div>
                            <p><strong>{topic.topic}</strong></p>
                            <ul>
                                {
                                    topic.details.map(detail => (
                                        <li>{detail}</li>
                                    ))
                                }
                            </ul>
                        </div>

                    ))
                }
            </div>}

            <button onClick={colapesing} className={css.view__more}>{colapesed ? "Ver mas" : "Ver menos"}</button>
        </div>
    )
}
