import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import css from './ProfilePage.module.css'
import { useForm } from '../../hooks/useForm';
import { validateEmail } from '../../helpers/validateEmail';

import { DecorativeSquare, Input } from '../../components/index'

import { useAuth } from "../../contexts/AuthContext";
import { interests } from '../../util/interests';
import { updateUser, updateUserProfile } from '../../model/users';
import classNames from 'classnames';

const ProfilePage = () => {

    const navigate = useNavigate();
    const [animate, setAnimate] = useState(false);

    const { user, error, handleUpdateUser } = useAuth();
    const [steps, setSteps] = useState(0)
    const [values, setValues] = useState({})

    if (error) {
        navigate('/login')
    }

    useEffect(() => {
    if (user?.profile?.hobbies && user?.profile?.education_level && user?.name && user?.last_name) {
        navigate('/');
    }
}, [user, navigate]);

useEffect(() => {
    setAnimate(true);
    const timer = setTimeout(() => setAnimate(false), 2000); // Adjust the timeout duration as needed
    return () => clearTimeout(timer);
}, [steps]);

    const STEPS = [
        {
            title: "¡Bienvenido! ¿Cuál es tu nombre?",
            input: "name",
            type: "text",
            placeholder: "Escribe tu nombre aquí...",
            onChange: (e) => {
                e.preventDefault()
                const value = e.target.value

                setValues({ ...values, name: value })
            }
        },
        {
            title: "¿Y tu apellido?",
            input: "last_name",
            type: "text",
            placeholder: "Escribe tu apellido aquí...",
            onChange: (e) => {
                e.preventDefault()
                const value = e.target.value

                setValues({ ...values, last_name: value })
            }
        },
        {
            title: "¿Cuál es tu nivel de estudios?",
            input: "education_level",
            type: "text",
            placeholder: "Escribe tu nivel de estudios aquí...",
            onChange: (e) => {
                e.preventDefault()
                const value = e.target.value

                setValues({ ...values, education_level: value })
            }
        },
        {
            title: "¿Cuáles son tus hobbies?",
            input: "hobbies",
            type: "grid",
            placeholder: "Escribe tus hobbies aquí...",
            onChange: (e) => {
                const value = e.target.value
                const isChecked = e.target.checked

                if (isChecked) {
                    const hobbies = values.hobbies || []
                    setValues({ ...values, hobbies: [...hobbies, value] })
                } else {
                    setValues({ ...values, hobbies: values.hobbies?.filter(hobby => hobby !== value) || [] })
                }
            }
        }
    ]

    const handleUpdateProfile = async (values) => {
        try {
            await updateUser(user.id_user, {
                name: values.name,
                last_name: values.last_name,
            })
            await updateUserProfile(user.id_user, {
                hobbies: JSON.stringify(values.hobbies),
                education_level: values.education_level,
            })
            await handleUpdateUser(user.id_user)

            navigate('/')
            
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className={css.login__container}>
            <div className={css.login__box}>
                <DecorativeSquare color="#26d07c" positionTop="150px" positionRight="100px" />
                <DecorativeSquare color="#007864" positionTop="250px" positionRight="0px" />
                <DecorativeSquare color="#00a499" positionBottom="0px" positionLeft="5%" />

                <h1 className={classNames(css.title, {
                    [css.typewriter]: animate,
                    [css.center]: STEPS[steps].type === "grid"
                })}>{STEPS[steps].title}</h1>
                {STEPS[steps].type === "grid" ? (
                    <div className={css.checkbox__group}>
                        {interests.map((interest, index) => (
                            <label key={index} className={css.option}>   
                                <img src={interest.path} alt={interest.label} />
                                <span className={css.label}>
                                {interest.label}
                                <input
                                    type="checkbox"
                                    name={STEPS[steps].input}
                                    value={interest.id}
                                    onChange={STEPS[steps].onChange}
                                />
                                </span>
                            </label>
                        ))}
                    </div>
                ) : (
                    <Input
                        id={STEPS[steps].input}
                        name={STEPS[steps].input}
                        type={STEPS[steps].type}
                        placeholder={STEPS[steps].placeholder}
                        onChange={STEPS[steps].onChange}
                    />
                )}
                <button 
                    onClick={() => {
                        if (steps < STEPS.length - 1) {
                            const input = document.getElementById(STEPS[steps].input)
                            input.value = ""
                            
                            setSteps(steps + 1)

                        } else {
                            handleUpdateProfile(values)
                        }
                    }} 
                    className={css.login__button}
                >
                    Siguiente
                </button>

            </div>
        </div>
    );
};

export default ProfilePage;
