import PropTypes from "prop-types";

import css from "./RadioRange.module.css";

const RadioRange = (props) => {
    const {
        options,
        name,
        label,
    } = props;
    
    return (
        <div className={css.container}>
            <label className={css.label}>{label}</label>
            <div className={css.radio__group} style={{gridTemplateColumns: `repeat(${options.length}, 1fr)`}}>
                {options.map((option, index) => (
                    <label key={index} className={css.option}>
                        <input
                            type="radio"
                            id={name}
                            name={name}
                            value={option.id_option}
                        />
                        {option.label}
                    </label>
                ))}
            </div>
        </div>
    );
}

RadioRange.propTypes = {
    options: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default RadioRange;