import React from 'react'
import classNames from 'classnames'

import css from './DecorativeSquare.module.css'

const DecorativeSquare = ({color = "#00c181", positionTop, positionRight, positionLeft, positionBottom, hide}) => {
    return (
        <div 
        className={classNames(css.decorative__square ,{
            [css.hide]: hide
        })}
            style={{
                backgroundColor: color,
                top: positionTop,
                right: positionRight,
                left: positionLeft,
                bottom: positionBottom
            }}
            key={color+positionTop}></div>
    )
}


export default DecorativeSquare;