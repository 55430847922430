import PropTypes from 'prop-types'

import css from './CheckboxGroup.module.css'

const CheckboxGroup = (props) => {
    const {
        options,
        name,
        label,
        onChange,
    } = props

    return (
        <div className={css.container}>
            <label className={css.label}>{label}</label>
            <div className={css.checkbox__group}>
                {options.map((option, index) => (
                    <label key={index} className={css.option}>
                        <input
                            type="checkbox"
                            name={name}
                            value={option.id_option}
                        />
                        {option.label}
                    </label>
                ))}
            </div>
        </div>
    )
}



export default CheckboxGroup