import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './Button.module.css';

const Button = props => {

    const {
        icon, 
        text,
        className,
        disabled,
        ...rest
    } = props;

    const classes = classNames(css.root, className);

    return (
        <button className={classes} {...rest}>
            {icon && <img src={icon} alt="" />}
            {text}
        </button>
    );
}

Button.propTypes = {
    icon: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
}

Button.defaultProps = {
    icon: null,
    text: 'Click me',
    className: '',
    onClick: () => { console.log('Button clicked') },
    disabled: false
}

export default Button;