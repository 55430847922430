import React from 'react'
import classNames from 'classnames'

import css from './Input.module.css'

const Input = ({ id, type, placeholder, isValid = true, value, key, name, onChange, className }) => {
    return (
        <input
            id={id}
            type={type}
            placeholder={placeholder}
            className={classNames(css.input, className, {
                [css.input__invalid]: !isValid
            })}
            value={value}
            key={key}
            name={name}
            onChange={onChange}
        />
    )
}


export default Input;