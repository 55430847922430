import React, { useEffect, useState } from 'react';
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom';
import css from './LoginPage.module.css'
import { useForm } from '../../hooks/useForm';
import { validateEmail } from '../../helpers/validateEmail';

import { DecorativeSquare, Input } from '../../components/index'

import { useAuth } from "../../contexts/AuthContext";
import { validateExistUser } from '../../model/auth';

const LoginPage = () => {

    const navigate = useNavigate();

    const { user, handleLogin, handleSignup } = useAuth();

    const [emailIsValid, setEmailIsValid] = useState(true)
    const [passwordIsValid, setPasswordIsValid] = useState(true)
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const [pageState, setPageState] = useState("email")

    const [formValues, , handleInputChange] = useForm({
        emailText: "",
        passwordText: ""
    })

    const { emailText, passwordText } = formValues

    useEffect(() => {
        if (user) {
            navigate('/')
        }
    }, [user, navigate])
    
    useEffect(() => {

        setEmailIsValid(emailText === "" ? true : validateEmail(emailText))
        setIsButtonDisabled(emailText === "" ? false : validateEmail(emailText))

    }, [emailText])

    useEffect(() => {
        setPasswordIsValid(passwordText.length > 8 ? true : false)
    }, [passwordText])

    const changeState = async (step) => {
        if (step === "next") {
            if (pageState === "email") {
                validateExistUser(emailText)
                    .then(result => {
                        if (result.status === 404) {
                            setPageState("signup")
                        } else {
                            setPageState("password")
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        setPageState("signup")
                    })

            } else if (pageState === "password") {
                handleLogin({
                    email: emailText,
                    password: passwordText,
                })
                .then((result) => {
                    navigate('/');
                })
                .catch((error) => {
                    console.log(error)
                })
            } else if (pageState === "signup") {
                console.log("signup")
                handleSignup({
                    email: emailText,
                    password: passwordText,
                })
                .then((result) => {
                    navigate('/');
                })
                .catch((error) => {
                    console.log(error)
                })
            }
        } else if (step === "back") {
            if (pageState === "password") {
                setPageState("email")
            }
        }
    }

    return (
        <div className={css.login__container}>
            <div className={css.login__box}>

                <DecorativeSquare color="#26d07c" positionTop="150px" positionRight="100px" />
                <DecorativeSquare color="#007864" positionTop="250px" positionRight="0px" />
                <DecorativeSquare color="#00a499" positionBottom="0px" positionLeft="5%" />
                {
                    pageState === "email" ?
                        <div>
                            <h1 className={css.typewriter}>Hola, ¿Quién está ahí?</h1>
                            <Input
                                type="text"
                                placeholder="Escribe tu correo aquí..."
                                isValid={emailIsValid}
                                value={emailText}
                                key='emailText'
                                name='emailText'
                                onChange={handleInputChange}
                            />
                            <button className={css.login__button} onClick={(e) => changeState("next")}>Continuar</button>
                        </div>
                        :
                        <div>
                            <h1 className={classNames({
                                [css.typewriter]: pageState !== "email"
                            })}>{pageState === "signup" ? `¿Cuál será tu palabra secreta?` : `Psssst, pssst ¿Tienes la clave?`}</h1>
                            {/*} <button className={css.login__button} disabled={!isButtonDisabled}  onClick={(e) => changeState("back")}>Atras</button>*/}
                            <Input
                                type="password"
                                placeholder="Escribe tu contraseña"
                                isValid={passwordIsValid}
                                value={passwordText}
                                key='passwordText'
                                name='passwordText'
                                onChange={handleInputChange}
                            />
                            <button className={css.login__button} disabled={!isButtonDisabled} onClick={(e) => changeState("next")}>Continuar</button>
                        </div>
                }
            </div>
        </div>
    );
};

export default LoginPage;
