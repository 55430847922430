export const holland = {
    "id": "vocacionamiento",
    "title": "Vocacionamiento",
    "description": "El test de Holland es una herramienta que te ayudará a identificar tus intereses vocacionales y a encontrar una carrera que se ajuste a tus gustos y habilidades.",
    "instructions": "El test de Holland es una herramienta que te ayudará a identificar tus intereses vocacionales y a encontrar una carrera que se ajuste a tus gustos y habilidades.",
    "exam": [
        {
            "title": "parteA",
            "questions": [
                {
                    "id_question": "A001",
                    "label": "Marque con una X todos los adjetivos que describan su personalidad.",
                    "question_type": "checkbox_group",
                    "options": [
                        { "id_option": "A001_01", "label": "Huraño" },
                        { "id_option": "A001_02", "label": "Discutidor" },
                        { "id_option": "A001_03", "label": "Arrogante" },
                        { "id_option": "A001_04", "label": "Capaz" },
                        { "id_option": "A001_05", "label": "Común y Corriente" },
                        { "id_option": "A001_06", "label": "Conformista" },
                        { "id_option": "A001_07", "label": "Concienzudo" },
                        { "id_option": "A001_08", "label": "Curioso" },
                        { "id_option": "A001_09", "label": "Dependiente" },
                        { "id_option": "A001_10", "label": "Eficiente" },
                        { "id_option": "A001_11", "label": "Paciente" },
                        { "id_option": "A001_12", "label": "Dinámico" },
                        { "id_option": "A001_13", "label": "Femenino" },
                        { "id_option": "A001_14", "label": "Amistoso" },
                        { "id_option": "A001_15", "label": "Generoso" },
                        { "id_option": "A001_16", "label": "Dispuesto a ayudar" },
                        { "id_option": "A001_17", "label": "Inflexible" },
                        { "id_option": "A001_18", "label": "Insensible" },
                        { "id_option": "A001_19", "label": "Introvertido" },
                        { "id_option": "A001_20", "label": "Intuitivo" },
                        { "id_option": "A001_21", "label": "Irritable" },
                        { "id_option": "A001_22", "label": "Amable" },
                        { "id_option": "A001_23", "label": "De buenos modales" },
                        { "id_option": "A001_24", "label": "Varonil" },
                        { "id_option": "A001_25", "label": "Inconforme" },
                        { "id_option": "A001_26", "label": "Poco realista" },
                        { "id_option": "A001_27", "label": "Poco culto" },
                        { "id_option": "A001_28", "label": "Poco idealista" },
                        { "id_option": "A001_29", "label": "Impopular" },
                        { "id_option": "A001_30", "label": "Original" },
                        { "id_option": "A001_31", "label": "Pesimista" },
                        { "id_option": "A001_32", "label": "Hedonista" },
                        { "id_option": "A001_33", "label": "Práctico" },
                        { "id_option": "A001_34", "label": "Rebelde" },
                        { "id_option": "A001_35", "label": "Reservado" },
                        { "id_option": "A001_36", "label": "Culto" },
                        { "id_option": "A001_37", "label": "Lento de movimientos" },
                        { "id_option": "A001_38", "label": "Sociable" },
                        { "id_option": "A001_39", "label": "Estable" },
                        { "id_option": "A001_40", "label": "Esforzado" },
                        { "id_option": "A001_41", "label": "Fuerte" },
                        { "id_option": "A001_42", "label": "Suspicaz" },
                        { "id_option": "A001_43", "label": "Cumplido" },
                        { "id_option": "A001_44", "label": "Modesto" },
                        { "id_option": "A001_45", "label": "Poco convencional" }
                    ]
                }
            ]
        },
        {
            "title": "parteB",
            "questions": [
                {
                    "id_question": "B001",
                    "label": "Distraído",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "B001_01", "label": "Más que los demás" },
                        { "id_option": "B001_02", "label": "Igual que los demás" },
                        { "id_option": "B001_03", "label": "Menos que los demás" }
                    ]
                },
                {
                    "id_question": "B002",
                    "label": "Capacidad artística",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "B002_01", "label": "Más que los demás" },
                        { "id_option": "B002_02", "label": "Igual que los demás" },
                        { "id_option": "B002_03", "label": "Menos que los demás" }
                    ]
                },
                {
                    "id_question": "B003",
                    "label": "Capacidad burocrática",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "B003_01", "label": "Más que los demás" },
                        { "id_option": "B003_02", "label": "Igual que los demás" },
                        { "id_option": "B003_03", "label": "Menos que los demás" }
                    ]
                },
                {
                    "id_question": "B004",
                    "label": "Conservador",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "B004_01", "label": "Más que los demás" },
                        { "id_option": "B004_02", "label": "Igual que los demás" },
                        { "id_option": "B004_03", "label": "Menos que los demás" }
                    ]
                },
                {
                    "id_question": "B005",
                    "label": "Cooperación",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "B005_01", "label": "Más que los demás" },
                        { "id_option": "B005_02", "label": "Igual que los demás" },
                        { "id_option": "B005_03", "label": "Menos que los demás" }
                    ]
                },
                {
                    "id_question": "B006",
                    "label": "Expresividad",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "B006_01", "label": "Más que los demás" },
                        { "id_option": "B006_02", "label": "Igual que los demás" },
                        { "id_option": "B006_03", "label": "Menos que los demás" }
                    ]
                },
                {
                    "id_question": "B007",
                    "label": "Liderazgo",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "B007_01", "label": "Más que los demás" },
                        { "id_option": "B007_02", "label": "Igual que los demás" },
                        { "id_option": "B007_03", "label": "Menos que los demás" }
                    ]
                },
                {
                    "id_question": "B008",
                    "label": "Gusto en ayudar a los demás",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "B008_01", "label": "Más que los demás" },
                        { "id_option": "B008_02", "label": "Igual que los demás" },
                        { "id_option": "B008_03", "label": "Menos que los demás" }
                    ]
                },
                {
                    "id_question": "B009",
                    "label": "Capacidad matemática",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "B009_01", "label": "Más que los demás" },
                        { "id_option": "B009_02", "label": "Igual que los demás" },
                        { "id_option": "B009_03", "label": "Menos que los demás" }
                    ]
                },
                {
                    "id_question": "B010",
                    "label": "Capacidad mecánica",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "B010_01", "label": "Más que los demás" },
                        { "id_option": "B010_02", "label": "Igual que los demás" },
                        { "id_option": "B010_03", "label": "Menos que los demás" }
                    ]
                },
                {
                    "id_question": "B011",
                    "label": "Originalidad",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "B011_01", "label": "Más que los demás" },
                        { "id_option": "B011_02", "label": "Igual que los demás" },
                        { "id_option": "B011_03", "label": "Menos que los demás" }
                    ]
                },
                {
                    "id_question": "B012",
                    "label": "Popularidad con el sexo opuesto",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "B012_01", "label": "Más que los demás" },
                        { "id_option": "B012_02", "label": "Igual que los demás" },
                        { "id_option": "B012_03", "label": "Menos que los demás" }
                    ]
                },
                {
                    "id_question": "B013",
                    "label": "Capacidad para investigar",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "B013_01", "label": "Más que los demás" },
                        { "id_option": "B013_02", "label": "Igual que los demás" },
                        { "id_option": "B013_03", "label": "Menos que los demás" }
                    ]
                },
                {
                    "id_question": "B014",
                    "label": "Capacidad científica",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "B014_01", "label": "Más que los demás" },
                        { "id_option": "B014_02", "label": "Igual que los demás" },
                        { "id_option": "B014_03", "label": "Menos que los demás" }
                    ]
                },
                {
                    "id_question": "B015",
                    "label": "Seguridad en sí mismo",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "B015_01", "label": "Más que los demás" },
                        { "id_option": "B015_02", "label": "Igual que los demás" },
                        { "id_option": "B015_03", "label": "Menos que los demás" }
                    ]
                },
                {
                    "id_question": "B016",
                    "label": "Comprensión de sí mismo",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "B016_01", "label": "Más que los demás" },
                        { "id_option": "B016_02", "label": "Igual que los demás" },
                        { "id_option": "B016_03", "label": "Menos que los demás" }
                    ]
                },
                {
                    "id_question": "B017",
                    "label": "Comprensión de los demás",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "B017_01", "label": "Más que los demás" },
                        { "id_option": "B017_02", "label": "Igual que los demás" },
                        { "id_option": "B017_03", "label": "Menos que los demás" }
                    ]
                },
                {
                    "id_question": "B018",
                    "label": "Pulcritud",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "B018_01", "label": "Más que los demás" },
                        { "id_option": "B018_02", "label": "Igual que los demás" },
                        { "id_option": "B018_03", "label": "Menos que los demás" }
                    ]
                }
            ]
        },
        {
            "title": "parteC",
            "questions": [
                {
                    "id_question": "C001",
                    "label": "Estar feliz y satisfecho",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "C001_01", "label": "Muy Importante" },
                        { "id_option": "C001_02", "label": "Más o Menos Importante" },
                        { "id_option": "C001_03", "label": "Poco Importante" }
                    ]
                },
                {
                    "id_question": "C002",
                    "label": "Descubrir o elaborar un producto útil",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "C002_01", "label": "Muy Importante" },
                        { "id_option": "C002_02", "label": "Más o Menos Importante" },
                        { "id_option": "C002_03", "label": "Poco Importante" }
                    ]
                },
                {
                    "id_question": "C003",
                    "label": "Ayudar a quiénes están en apuros",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "C003_01", "label": "Muy Importante" },
                        { "id_option": "C003_02", "label": "Más o Menos Importante" },
                        { "id_option": "C003_03", "label": "Poco Importante" }
                    ]
                },
                {
                    "id_question": "C004",
                    "label": "Llegar a ser una autoridad en algún tema",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "C004_01", "label": "Muy Importante" },
                        { "id_option": "C004_02", "label": "Más o Menos Importante" },
                        { "id_option": "C004_03", "label": "Poco Importante" }
                    ]
                },
                {
                    "id_question": "C005",
                    "label": "Llegar a ser un deportista destacado",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "C005_01", "label": "Muy Importante" },
                        { "id_option": "C005_02", "label": "Más o Menos Importante" },
                        { "id_option": "C005_03", "label": "Poco Importante" }
                    ]
                },
                {
                    "id_question": "C006",
                    "label": "Llegar a ser un líder en la comunidad",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "C006_01", "label": "Muy Importante" },
                        { "id_option": "C006_02", "label": "Más o Menos Importante" },
                        { "id_option": "C006_03", "label": "Poco Importante" }
                    ]
                },
                {
                    "id_question": "C007",
                    "label": "Ser influyente en asuntos públicos",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "C007_01", "label": "Muy Importante" },
                        { "id_option": "C007_02", "label": "Más o Menos Importante" },
                        { "id_option": "C007_03", "label": "Poco Importante" }
                    ]
                },
                {
                    "id_question": "C008",
                    "label": "Observar una conducta religiosa formal",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "C008_01", "label": "Muy Importante" },
                        { "id_option": "C008_02", "label": "Más o Menos Importante" },
                        { "id_option": "C008_03", "label": "Poco Importante" }
                    ]
                },
                {
                    "id_question": "C009",
                    "label": "Contribuir a la ciencia en forma teórica",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "C009_01", "label": "Muy Importante" },
                        { "id_option": "C009_02", "label": "Más o Menos Importante" },
                        { "id_option": "C009_03", "label": "Poco Importante" }
                    ]
                },
                {
                    "id_question": "C010",
                    "label": "Contribuir a la ciencia en forma técnica",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "C010_01", "label": "Muy Importante" },
                        { "id_option": "C010_02", "label": "Más o Menos Importante" },
                        { "id_option": "C010_03", "label": "Poco Importante" }
                    ]
                },
                {
                    "id_question": "C011",
                    "label": "Escribir bien (novelas, poemas)",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "C011_01", "label": "Muy Importante" },
                        { "id_option": "C011_02", "label": "Más o Menos Importante" },
                        { "id_option": "C011_03", "label": "Poco Importante" }
                    ]
                },
                {
                    "id_question": "C012",
                    "label": "Haber leído mucho",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "C012_01", "label": "Muy Importante" },
                        { "id_option": "C012_02", "label": "Más o Menos Importante" },
                        { "id_option": "C012_03", "label": "Poco Importante" }
                    ]
                },
                {
                    "id_question": "C013",
                    "label": "Trabajar mucho",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "C013_01", "label": "Muy Importante" },
                        { "id_option": "C013_02", "label": "Más o Menos Importante" },
                        { "id_option": "C013_03", "label": "Poco Importante" }
                    ]
                },
                {
                    "id_question": "C014",
                    "label": "Contribuir al bienestar humano",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "C014_01", "label": "Muy Importante" },
                        { "id_option": "C014_02", "label": "Más o Menos Importante" },
                        { "id_option": "C014_03", "label": "Poco Importante" }
                    ]
                },
                {
                    "id_question": "C015",
                    "label": "Crear buenas obras artísticas (teatro, pintura)",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "C015_01", "label": "Muy Importante" },
                        { "id_option": "C015_02", "label": "Más o Menos Importante" },
                        { "id_option": "C015_03", "label": "Poco Importante" }
                    ]
                },
                {
                    "id_question": "C016",
                    "label": "Llegar a ser un buen músico",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "C016_01", "label": "Muy Importante" },
                        { "id_option": "C016_02", "label": "Más o Menos Importante" },
                        { "id_option": "C016_03", "label": "Poco Importante" }
                    ]
                },
                {
                    "id_question": "C017",
                    "label": "Llegar a ser un experto en finanzas y negocios",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "C017_01", "label": "Muy Importante" },
                        { "id_option": "C017_02", "label": "Más o Menos Importante" },
                        { "id_option": "C017_03", "label": "Poco Importante" }
                    ]
                },
                {
                    "id_question": "C018",
                    "label": "Hallar un propósito real en la vida",
                    "question_type": "radio_range",
                    "options": [
                        { "id_option": "C018_01", "label": "Muy Importante" },
                        { "id_option": "C018_02", "label": "Más o Menos Importante" },
                        { "id_option": "C018_03", "label": "Poco Importante" }
                    ]
                }
            ]
        },

        {
            "title": "parteD",
            "questions": [
                {
                    "id_question": "D001",
                    "label": "Me gusta...",
                    "question_type": "radio",
                    "options": [
                        { "id_option": "D001_01", "label": "Leer y meditar sobre los problemas" },
                        { "id_option": "D001_02", "label": "Anotar datos y hacer cómputos" },
                        { "id_option": "D001_03", "label": "Tener una posición poderosa" },
                        { "id_option": "D001_04", "label": "Enseñar o ayudar a los demás" },
                        { "id_option": "D001_05", "label": "Trabajar manualmente, usar equipos, herramientas" },
                        { "id_option": "D001_06", "label": "Usar mi talento artístico" }
                    ]
                },
                {
                    "id_question": "D002",
                    "label": "Mi mayor habilidad se manifiesta en...",
                    "question_type": "radio",
                    "options": [
                        { "id_option": "D002_01", "label": "Negocios" },
                        { "id_option": "D002_02", "label": "Artes" },
                        { "id_option": "D002_03", "label": "Ciencias" },
                        { "id_option": "D002_04", "label": "Liderazgo" },
                        { "id_option": "D002_05", "label": "Relaciones Humanas" },
                        { "id_option": "D002_06", "label": "Mecánica" }
                    ]
                },
                {
                    "id_question": "D003",
                    "label": "Soy muy incompetente en...",
                    "question_type": "radio",
                    "options": [
                        { "id_option": "D003_01", "label": "Mecánica" },
                        { "id_option": "D003_02", "label": "Ciencia" },
                        { "id_option": "D003_03", "label": "Relaciones Humanas" },
                        { "id_option": "D003_04", "label": "Negocios" },
                        { "id_option": "D003_05", "label": "Liderazgo" },
                        { "id_option": "D003_06", "label": "Artes" }
                    ]
                },
                {
                    "id_question": "D004",
                    "label": "Si tuviera que realizar alguna de estas actividades, la que menos me agradaría es...",
                    "question_type": "radio",
                    "options": [
                        { "id_option": "D004_01", "label": "Participar en actividades sociales muy formales" },
                        { "id_option": "D004_02", "label": "Tener una posición de responsabilidad" },
                        { "id_option": "D004_03", "label": "Llevar pacientes mentales a actividades recreativas" },
                        { "id_option": "D004_04", "label": "Llevar registros exactos y complejos" },
                        { "id_option": "D004_05", "label": "Escribir un poema" },
                        { "id_option": "D004_06", "label": "Hacer algo que exija paciencia y precisión" }
                    ]
                },
                {
                    "id_question": "D005",
                    "label": "Las materias que más me gustan son...",
                    "question_type": "radio",
                    "options": [
                        { "id_option": "D005_01", "label": "Arte" },
                        { "id_option": "D005_02", "label": "Administración, contabilidad" },
                        { "id_option": "D005_03", "label": "Química, Física" },
                        { "id_option": "D005_04", "label": "Educación tecnológica, Mecánica" },
                        { "id_option": "D005_05", "label": "Historia" },
                        { "id_option": "D005_06", "label": "Ciencias sociales, Filosofía" }
                    ]
                }
            ]
        }
    ]
}