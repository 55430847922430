import React, { useState, useEffect, useContext, useCallback } from 'react';
import { login, signup, loginWithToken } from '../model/auth';
import { getUserById } from '../model/users';
import Cookies from 'js-cookie';

const AuthContext = React.createContext();

export function AuthProvider(props) {
  const {
    children
  } = props;

  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      loginWithToken(token)
        .then(user => {
          Cookies.set('token', user.token);
          setUser(user.user[0]);
        })
        .catch(error => {
          setError(error);
        });
    } else {
      setError({ message: 'No token' });
    }
  }, []);

  const handleLogin = useCallback(async (body) => {

    return login(body)
        .then(res => {
            setUser(res.user[0]);
            setError(null);
            Cookies.set('token', res.token);
        })
        .catch(error => Promise.reject({error}));
}, []);

const handleSignup = useCallback(async (body) => {
    return signup(body)
        .then(res => {
            setUser(res.user[0]);
            setError(null);
            Cookies.set('token', res.token);
        })
        .catch(error => Promise.reject(error));
}, []);

const handleLogout = useCallback(() => {
    setUser(null);
    Cookies.remove('token');
}, []);

const handleUpdateUser = useCallback(async (id) => {
    return getUserById(id)
        .then(res => {
            console.log("res", res)
            setUser(res[0]);
        })
        .catch(error => Promise.reject(error));
})

  const value = {
    user,
    error,
    handleLogin,
    handleSignup,
    handleLogout,
    handleUpdateUser
  };

  return (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}