import React from 'react'
import css from './index.module.css';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import { Menu } from './components';
import { routes } from "./routes";

import { useAuth } from "./contexts/AuthContext";
const router = createBrowserRouter(routes);

export const Framework = () => {

    const { user, error } = useAuth();

    const [showMenu, setShowMenu] = React.useState(false)

    React.useEffect(() => {
        if (user?.profile?.hobbies && user?.profile?.education_level && user?.name && user?.last_name) {
            setShowMenu(true)
        } else {
            setShowMenu(false)
        }
    }, [user])
    
    return (
        <div className={css.main__container}>
            {showMenu && <Menu />}
            <div className={showMenu ? css.content : css.content_full} >
                <RouterProvider router={router} />
            </div>
        </div>
    )
}
