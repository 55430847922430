import axios from 'axios';

const apiUrl = process.env.REACT_APP_URL_API || 'http://localhost:3500';

let instance = axios.create({
    baseURL: apiUrl,
});

// export const validateExistUser = async (emailText) => {
//     return await worker('get', `auth/validate-user-exist/${emailText}`)
// }

export const validateExistUser = async (emailText) => {
    const url = `/auth/validate-user-exist/${emailText}`;
    return instance.get(url)
        .then(response => response.data)
        .catch(error => Promise.reject(error.response.data));
}

export const login = async (body) => {
    const url = '/auth/login';
    return instance.post(url, body)
        .then(response => response.data)
        .catch(error => Promise.reject(error.response.data));
};

export const signup = async (body) => {
    const url = '/auth/signup';
    return instance.post(url, body)
        .then(response => response.data)
        .catch(error => Promise.reject(error.response.data));
}

export const loginWithToken = async (token) => {
    const url = '/auth/me';
    return instance.get(url, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => response.data)
        .catch(error => Promise.reject(error.response.data));
}