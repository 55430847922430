import React from 'react';
import css from './ResultPage.module.css';
import { RelatedCareers } from '../../components/RelatedCareers/RelatedCareers';

import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

const exampleData = {
    results: [38, 22, 10, 30, 56, 20, 25],
    date: "Mie 7 de Agosto 2024, 7:00 am",
    description: ` Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    related_careers: [
        {
            career: "Diseño Gráfico y Animación",
            topics: [{
                topic: "Diseño Gráfico",
                details: [
                    "Desarrollo de Marcas: Trabajar en la identidad visual de empresas, incluyendo logotipos, paletas de colores y tipografías.",
                    "Colaboración: Trabajar con otros diseñadores, equipos de marketing y clientes para entender necesidades y objetivos.",
                    "Uso de Software de Diseño: Utilizar herramientas como Adobe Photoshop, Illustrator e InDesign para crear y editar diseños.",
                    "Revisión y Corrección de Pruebas: Asegurarse de que los diseños sean precisos y cumplan con las especificaciones antes de la producción final."
                ]
            }, {
                topic: "Animación",
                details: [
                    "Storyboard: Crear guiones gráficos que establecen el flujo y las escenas de una animación.",
                    "Modelado y Animación 3D: Utilizar software como Maya, Blender o 3ds Max para crear personajes y escenarios en 3D.",
                    "Animación 2D: Dibujar y animar personajes y movimientos en 2D usando software como Adobe Animate.",
                    "Edición de Video: Editar y componer secuencias de video, incorporando elementos animados.",
                    "Colaboración en Proyectos: Trabajar con guionistas, diseñadores de sonido y otros animadores para producir proyectos completos."
                ]
            }]
        },
        {
            career: "Ingeniería en Desarrollo de Software",
            topics: [{
                topic: "Desarrollo de Software",
                details: [
                    "Diseño de Software: Planificar, diseñar y desarrollar soluciones de software escalables y eficientes.",
                    "Programación: Escribir código limpio y funcional utilizando lenguajes de programación como Java, Python, C++, y más.",
                    "Control de Versiones: Utilizar herramientas como Git para gestionar el código fuente y colaborar con equipos de desarrollo.",
                    "Metodologías Ágiles: Aplicar Scrum o Kanban para la gestión de proyectos y entrega continua de software.",
                    "Pruebas y Depuración: Probar y depurar software para garantizar su funcionalidad, eficiencia y seguridad."
                ]
            }, {
                topic: "Desarrollo Web",
                details: [
                    "Desarrollo Frontend: Crear interfaces de usuario utilizando tecnologías como HTML, CSS y JavaScript.",
                    "Desarrollo Backend: Crear y gestionar la lógica del servidor utilizando tecnologías como Node.js, Django o Spring.",
                    "Bases de Datos: Diseñar, implementar y optimizar bases de datos SQL o NoSQL para almacenamiento eficiente.",
                    "Seguridad Web: Implementar prácticas de seguridad para proteger aplicaciones web de amenazas comunes.",
                    "Despliegue y Mantenimiento: Gestionar el despliegue de aplicaciones web y su mantenimiento en entornos de producción."
                ]
            }]
        }
    ]
}

const data = {
    labels: ['Convencional', 'Artista', 'Investigadora', 'Realista', 'Social', 'Emprendedor', "Promedio"],
    datasets: [
        {
            data: exampleData.results,
            backgroundColor: [
                "#7030A0",
                "#FFC000",
                "#00B0F0",
                "#F00",
                "#757171",
                "#92D050",
                "#000"
            ],
            borderWidth: 1,
        },
    ],
};

const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: true,
            text: 'Test de holland',
        },
    },
    scales: {
        y: {
            beginAtZero: true,
            max: 100,
            ticks: {
                stepSize: 20,
            },
        },
    },
};



const ResultPage = () => {

    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

    return (
        <div className={css.report__section}>
            <nav className={css.breadcrumb}>
                <span>Inicio</span> &gt; <span>Vocacionamiento</span> &gt; <span>Reporte de resultados</span>
            </nav>

            <h1 className={css.title}>Reporte de resultados</h1>
            <p className={css.date}>Realizado el {exampleData.date}</p>

            <p className={css.description}>
                {exampleData.description}
            </p>

            <Bar data={data} options={options} />

            <h2 className={css.subheading}>Carreras más afines</h2>
            {
                exampleData.related_careers.map(career => (
                    <RelatedCareers career={career} />
                ))
            }
        </div>
    );
};

export default ResultPage;
