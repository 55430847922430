import axios from 'axios';

const apiUrl = process.env.REACT_APP_URL_API || 'http://localhost:3500';

let instance = axios.create({
    baseURL: apiUrl + '/user'
});

export const getUserById = async (id) => {
    const url = `/user/${id}`;
    return instance.get(url)
        .then(response => response.data)
        .catch(error => Promise.reject(error.response.data));
}

export const updateUser = async (id, body) => {
    const url = `/user/${id}`;
    return instance.put(url, body)
        .then(response => response.data)
        .catch(error => Promise.reject(error.response.data));
}

export const updateUserProfile = async (id, body) => {
    const url = `/profile/${id}`;
    return instance.put(url, body)
        .then(response => response.data)
        .catch(error => Promise.reject(error.response.data));
}