import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { FullPageChat } from "flowise-embed-react"

import { Button, DecorativeSquare, ExamForm } from '../../components';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

import css from './AdmisionPage.module.css';
import { LeftArrow } from '../../assets/icons';
import { getQuestions } from '../../model/questions';
import { createAttempt, createResult } from '../../model/results';

const EXAM_ID = '5ca4ca39-bec5-4e9f-84db-18178d9fb39d';

const AdmisionChatbotPage = () => {
    const [startExam, setStartExam] = useState(false);
    const [currentSection, setCurrentSection] = useState(0);
    const [question, setQuestion] = useState(null);
    const [attempt, setAttempt] = useState(null);

    const { user, error } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (error) {
            navigate('/login');
        }
    }, [error, navigate]);

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const prompt = `Hola soy ${user?.name}, mi ultimo grado de estudios es ${user?.profile?.education_level}, mis hobbies son ${user?.profile?.hobbies} y me gustaría presentar mi examen de admisión.`;

                const response = await getQuestions({
                    chatId: user?.id_current_session,
                    question: prompt
                });

                let questionObject = JSON.parse(response.data.text);
                setQuestion(questionObject);
            } catch (err) {
                console.error('Failed to fetch questions:', err);
            }
        };

        const generateAttempt = async () => {
            try {
                const body = {
                    user_id: user?.id_user,
                    examId: EXAM_ID
                };

                const response = await createAttempt(body);
                setAttempt(response.data);
            } catch (err) {
                console.error('Failed to create attempt:', err);
            }
        };

        if (startExam) {
            fetchQuestions();

            if (!attempt) {
                generateAttempt();
            }
        }
    }, [startExam, user, attempt]);

    const handleSubmitResponse = async (values) => {
        try {
            const body = {
                id_attempt: attempt.id_attempt,
                id_user: user?.id_user,
                ...values[0],
                correct_answer: question.correctas
            };

            await createResult(body);
            // Reset form
            document.getElementById('admision-exam').reset();
            setQuestion(null);

            const newQuestion = await getQuestions({
                chatId: user?.id_current_session,
                question: body.answer_id
            });

            let questionObject = JSON.parse(newQuestion.data.text);
            setQuestion(questionObject);
            setCurrentSection(currentSection + 1);
        } catch (err) {
            console.error('Failed to submit response:', err);
        }
    };

    return (
        <div className={css.container}>
            <header className={classNames(css.header, {
                [css.compress]: startExam === true
            })}>
                <DecorativeSquare color="#26d07c" positionTop="16%" positionRight="100px" hide={startExam} />
                <DecorativeSquare color="#007864" positionTop="50%" positionRight="0px" hide={startExam} />
                <DecorativeSquare color="#00a499" positionTop="50%" positionLeft="0" hide={startExam} />
                <a className={css.breadcrumb} href="/">
                    <LeftArrow /><span>Atrás</span>
                </a>
                <h1>Examen de admisión</h1>
                <p>
                    Bienvenido al examen de admisión, a continuación se presentarán una serie de preguntas que deberás responder de acuerdo a tus preferencias.
                </p>
            </header>
            <section className={css.content}>
                <iframe src="https://flowise2.tecmilab.com.mx/chatbot/b7e9f8d9-9b43-45c8-ac72-3c5bc70a6c6e" width="100%" height="500px"></iframe>
    {/* <FullPageChat
        chatflowid="b7e9f8d9-9b43-45c8-ac72-3c5bc70a6c6e"
        apiHost="https://flowise2.tecmilab.com.mx"
        theme={{
            chatWindow: {
                showTitle: false,
                title: 'Flowise Bot',
                titleAvatarSrc: 'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
                showAgentMessages: true,
                welcomeMessage: 'Hello! This is custom welcome message',
                errorMessage: 'This is a custom error message',
                backgroundColor: "#ffffff",
                backgroundImage: 'enter image path or link', // If set, this will overlap the background color of the chat window.
                height: 500,
                width: "100dvh",
                fontSize: 16,
                // starterPrompts: ['What is a bot?', 'Who are you?'], // It overrides the starter prompts set by the chat flow passed
                starterPromptFontSize: 15,
                clearChatOnReload: false, // If set to true, the chat will be cleared when the page reloads.
                botMessage: {
                    backgroundColor: "#f7f8ff",
                    textColor: "#303235",
                    showAvatar: true,
                    avatarSrc: "https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/parroticon.png",
                },
                userMessage: {
                    backgroundColor: "#3B81F6",
                    textColor: "#ffffff",
                    showAvatar: true,
                    avatarSrc: "https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png",
                },
                textInput: {
                    placeholder: 'Type your question',
                    backgroundColor: '#ffffff',
                    textColor: '#303235',
                    sendButtonColor: '#3B81F6',
                    maxChars: 50,
                    maxCharsWarningMessage: 'You exceeded the characters limit. Please input less than 50 characters.',
                    autoFocus: false, // If not used, autofocus is disabled on mobile and enabled on desktop. true enables it on both, false disables it on both.
                    sendMessageSound: true,
                    // sendSoundLocation: "send_message.mp3", // If this is not used, the default sound effect will be played if sendSoundMessage is true.
                    receiveMessageSound: true,
                    // receiveSoundLocation: "receive_message.mp3", // If this is not used, the default sound effect will be played if receiveSoundMessage is true.
                },
                feedback: {
                    color: '#303235',
                },
                footer: {
                    textColor: '#303235',
                    text: 'Powered by',
                    company: 'Flowise',
                    companyLink: 'https://flowiseai.com',
                }
            }
        }}
    />*/ }
</section>
        </div>
    );
};

export default AdmisionChatbotPage;