import axios from 'axios';

const apiUrl = process.env.REACT_APP_URL_API || 'http://localhost:3500';

let instance = axios.create({
    baseURL: apiUrl + '/results'
});

export const createAttempt = async (body) => {
    const url = '/attempt-exam';
    return instance.post(url, body)
        .then(response => response.data)
        .catch(error => Promise.reject(error.response.data));
}

export const createResult = async (body) => {
    const url = '/create-result';
    return instance.post(url, body)
        .then(response => response.data)
        .catch(error => Promise.reject(error.response.data));
}
